<template>
    <div class="container">
        <span @click="$emit('close')" role="button"><small><i class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h2 class="my-3">Lisa meeldetuletus</h2>
        <div class="mb-3">
            <label for="descriptionInput" class="form-label"><small>Sisu</small></label>
            <textarea id="descriptionInput" class="form-control bg-light" v-model="reminder" required
                placeholder="Sisesta meeldetuletuse sisu"></textarea>
        </div>

        <div class="row mt-3">
            <div class="col">
                <button @click="$emit('close')" class="btn border-1 border-primary text-primary w-100">Loobu</button>
            </div>
            <div class="col">
                <button @click="submitReminder" class="btn btn-primary w-100">Lisa</button>
            </div>
        </div>

    </div>
</template>


<script>
import axios from '@/services/axios';

export default {
    props: {
        objectId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            reminder: ''
        };
    },
    methods: {
        async submitReminder() {
            try {
                const response = await axios.post(`/objects/${this.objectId}/reminders`, { reminder: this.reminder });

                if (response.status === 200) {
                    this.$emit('reminder-added', response.data.data);
                    this.reminder = '';
                    this.$emit('close');
                }

            } catch (error) {
                console.error('Error adding reminder:', error);
            }
        }
    }
};
</script>


<style scoped></style>
