<template>
    <LoginComponent />
</template>
  
<script>
import LoginComponent from '../components/LoginComponent.vue';

export default {
    components: {
        LoginComponent
    },
    mounted() {
        document.body.style.backgroundColor = 'white';
    },
    beforeUnmount() {
        document.body.style.backgroundColor = '';
    }
};
</script>
  