<template>
    <div>
      <label>{{ label }}</label>
      <canvas ref="canvas" class="signature-canvas" style="border: 1px solid #000; width: 300px; height: 150px;"></canvas>
      <input type="hidden" :name="name" :id="canvasId" :value="value" />
      <button type="button" @click="clearCanvas">Puhasta</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        canvasId: `canvas_${Math.random().toString(36).substr(2, 9)}`
      };
    },
    mounted() {
      this.initializeCanvas();
    },
    watch: {
      value(newValue) {
        this.loadImage(newValue);
      }
    },
    methods: {
      initializeCanvas() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        let isDrawing = false;
  
        const startDrawing = (event) => {
          isDrawing = true;
          ctx.beginPath();
          ctx.moveTo(event.offsetX || event.touches[0].clientX - canvas.getBoundingClientRect().left, 
                     event.offsetY || event.touches[0].clientY - canvas.getBoundingClientRect().top);
          event.preventDefault();
        };
  
        const draw = (event) => {
          if (isDrawing) {
            ctx.lineTo(event.offsetX || event.touches[0].clientX - canvas.getBoundingClientRect().left, 
                       event.offsetY || event.touches[0].clientY - canvas.getBoundingClientRect().top);
            ctx.stroke();
            event.preventDefault();
          }
        };
  
        const stopDrawing = (event) => {
          if (isDrawing) {
            isDrawing = false;
            ctx.closePath();
            this.updateSignature();
            event.preventDefault();
          }
        };
  
        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', stopDrawing);
        canvas.addEventListener('mouseleave', stopDrawing);
  
        canvas.addEventListener('touchstart', startDrawing);
        canvas.addEventListener('touchmove', draw);
        canvas.addEventListener('touchend', stopDrawing);
  
        this.loadImage(this.value);
      },
      clearCanvas() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.updateSignature();
      },
      updateSignature() {
        const canvas = this.$refs.canvas;
        const dataURL = canvas.toDataURL('image/png');
        this.$emit('update:value', dataURL);
      },
      loadImage(dataURL) {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = dataURL;
        image.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, 0, 0);
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .signature-canvas {
    width: 300px;
    height: 150px;
    border: 1px solid #000;
  }
  </style>
  