<template>
    <div class="container">
        <div v-if="loading">
        </div>
        <div v-else>
            <div v-if="workload">
                <div role="button" class="small" @click="goBack()"><i
                        class="fa-solid fa-chevron-left m-2 mt-3"></i>Tagasi
                </div>
                <h3 class="my-3">Tööajaarvestus</h3>
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <button class="btn btn-outline-primary" @click="goToPreviousMonth"><i class="fa-solid fa-chevron-left"></i></button>
                    <div>{{ formatDate(currentDate, 'MMMM yyyy') }}</div>
                    <button class="btn btn-outline-primary" @click="goToNextMonth"><i class="fa-solid fa-chevron-right"></i></button>
                </div>
                <div v-for="item in workload" :key="item.id" class="card border-0 mb-3 shadow">
                    <div class="card-body">
                        <div class="row">
                            <div class="col h6">{{ item.name }}</div>
                            <div class="col text-end small">
                                <div role="button" @click="openModal(item.items)">Vaata rohkem<i class="fa-solid fa-chevron-right ms-3"></i></div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col"><span class="text-danger h3 me-1">{{ item.count }}</span> kannet</div>
                            <div class="col"><span class="text-danger h3 me-1">{{ item.total }}</span> kokku</div>
                        </div>
                    </div>
                </div>
            </div>
            <NotFound v-else :item="'aruanne'" />
        </div>
        <WorkloadModal :show="isModalVisible" :items="selectedItems" @close="isModalVisible = false" />
    </div>
</template>

<script>
import axios from '@/services/axios';
import NotFound from '@/components/NotFound.vue';
import WorkloadModal from '@/components/WorkloadModal.vue';
import { format, addMonths, parseISO } from 'date-fns';
import { et as estonianLocale } from 'date-fns/locale';

export default {
    props: {
        yearMonth: {
            type: String,
            default: () => format(new Date(), 'yyyy-MM')
        }
    },
    data() {
        return {
            workload: null,
            loading: true,
            isModalVisible: false,
            selectedItems: [],
            currentDate: null
        };
    },
    components: {
        NotFound,
        WorkloadModal
    },
    methods: {
        async fetchWorkload() {
            this.loading = true;
            try {
                const response = await axios.get(`/workload`, { params: { date: this.yearMonth } });
                this.workload = response.data.data;
            } catch (error) {
                console.error('Error fetching workload details:', error);
            } finally {
                this.loading = false;
            }
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        openModal(items) {
            this.selectedItems = items;
            this.isModalVisible = true;
        },
        goToPreviousMonth() {
            const previousMonth = format(addMonths(this.currentDate, -1), 'yyyy-MM');
            this.$router.push(`/workload/${previousMonth}`);
        },
        goToNextMonth() {
            const nextMonth = format(addMonths(this.currentDate, 1), 'yyyy-MM');
            this.$router.push(`/workload/${nextMonth}`);
        },
        formatDate(date, dateFormat) {
            return format(date, dateFormat, { locale: estonianLocale });
        }
    },
    watch: {
        yearMonth: {
            immediate: true,
            handler(newVal) {
                const parsedDate = parseISO(`${newVal}-01`);
                if (isNaN(parsedDate)) {
                    this.currentDate = new Date();
                } else {
                    this.currentDate = parsedDate;
                }
                this.fetchWorkload();
            }
        }
    }
};
</script>