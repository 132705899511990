<template>
    <div class="container">
        <h1 class="my-3">Ladu</h1>
        <div v-if="stockItems.length > 0">
            <ul class="list-group mb-3">
                <li class="list-group-item">
                    <div class="row align-items-center">
                        <div class="col-7 fw-bold">
                            Materjal
                        </div>
                        <div class="col-3 fw-bold">
                            Kogus
                        </div>
                        <div class="col-2 text-end">
                        </div>
                    </div>
                </li>
                <li v-for="item in stockItems" :key="item.id" class="list-group-item">
                    <div class="row align-items-center">
                        <div class="col-7">
                            <div class="fw-bolder"><small>{{ item.resource_name }}</small></div>
                            <small>{{ item.sn }}</small>
                        </div>
                        <div class="col-3">
                            <small>{{ formatQuantity(item.quantity) }}</small>
                        </div>
                        <div class="col-2 text-end">

                        </div>
                    </div>
                </li>
            </ul>
            <ListPagination :currentPage="currentPage" :totalTasks="totalItems" :limit="limit"
                @page-changed="fetchStockItems" />
        </div>
        <div v-else>

        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import ListPagination from '@/components/ListPagination.vue';

export default {
    data() {
        return {
            stockItems: [],
            currentPage: 1,
            totalItems: 0,
            limit: 10
        };
    },
    components: {
        ListPagination
    },
    methods: {
        formatQuantity(quantity) {
            return parseFloat(quantity).toString();
        },
        async fetchStockItems(page = 1) {
            try {
                if (!this.currentUser) throw new Error("User not found");
                const response = await axios.get(`/stock-items?user_id=${this.currentUser.id}&page=${page}&limit=${this.limit}`);
                this.stockItems = response.data.data;
                this.totalItems = response.data.total;
                this.currentPage = page;
            } catch (error) {
                console.error('Error fetching stock items:', error);
            }
        }
    },
    mounted() {
        this.fetchStockItems();
    },
    computed: {
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    },

};
</script>