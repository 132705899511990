<template>
    <div v-if="show" class="item-modal bg-light">
        <div class="modal-content">
            <div class="container">
                <span @click="closeModal" role="button">
                    <small><i class="fa-solid fa-chevron-left me-2"></i>Tagasi</small>
                </span>
                <h2 class="my-3">Lisa aja sissekanne</h2>

                <div class="mb-3">
                    <label for="profile" class="form-label small">Töö liik</label>
                    <select v-model="profile" class="form-control bg-light">
                        <option v-for="profile in profiles" :key="profile.id" :value="profile.id">{{ profile.name }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="date" class="form-label small">Kuupäev</label>
                    <VueDatePicker v-model="date" :format="dateFormat" auto-apply input-class-name="bg-light"
                        locale="et" :clearable="false" required></VueDatePicker>
                </div>
                <div class="mb-3">
                    <label for="startTime" class="form-label small">Algus</label>
                    <VueDatePicker v-model="startTime" :format="timeFormat" :time-picker="true"
                        input-class-name="bg-light" locale="et" cancelText="Loobu" selectText="Vali" :clearable="false"
                        required></VueDatePicker>
                </div>
                <div class="mb-3">
                    <label for="duration" class="form-label small">Kestvus tundides</label>
                    <input id="duration" class="form-control bg-light" v-model="duration" placeholder="Kestvus"
                        type="text" inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                        @input="validateAmountInput('duration', $event.target.value)" />
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label small">Kirjeldus</label>
                    <textarea id="description" class="form-control bg-light" v-model="description" placeholder="Kirjeldus"
                        rows="3"></textarea>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <button @click="closeModal"
                            class="btn border-1 border-primary text-primary w-100">Loobu</button>
                    </div>
                    <div class="col">
                        <button @click="saveEvent" class="btn btn-primary w-100">Salvesta</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    components: {
        VueDatePicker
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        event: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            profiles: [],
            profile: null,
            date: null,
            startTime: null,
            duration: null,
            description: '',
            dateFormat: 'dd.MM.yyyy',
            timeFormat: 'HH:mm',
            isEditMode: false
        };
    },
    watch: {
        event: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.isEditMode = true;
                    this.profile = newVal.profile_id;
                    this.date = newVal.start_time.slice(0, 10);
                    this.startTime = {
                        hours: newVal.start_time.slice(11, 13),
                        minutes: newVal.start_time.slice(14, 16)
                    };
                    this.duration = newVal.duration;
                    this.description = newVal.description;
                } else {
                    this.isEditMode = false;
                    this.resetForm();
                }
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async fetchProfiles() {
            try {
                const response = await axios.get('/profiles?for_activity_resources_only=1&not_active=0');
                this.profiles = response.data.data;
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        },
        async saveEvent() {
            try {
                const d = new Date(this.date);
                d.setHours(this.startTime.hours, this.startTime.minutes, 0, 0);
                const formattedStartDateTime = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+(d.getMinutes().toString().length==2?d.getMinutes().toString():"0"+d.getMinutes().toString())+":00";
                const event = {
                    profile_id: this.profile,
                    start_time: formattedStartDateTime,
                    duration: this.duration,
                    description: this.description,
                };

                if (this.isEditMode) {
                    await axios.put(`/events/resources/${this.event.resource_id}`, event);
                } else {
                    await axios.post('/events/resources/', event);
                }
                this.$emit('event-saved');
                this.closeModal();
                this.resetForm();
            } catch (error) {
                console.error('Error saving event:', error);
            }
        },
        resetForm() {
            this.profile = null;
            this.date = null;
            this.startTime = null;
            this.duration = null;
            this.description = '';
        },
        validateAmountInput(propertyName, inputValue) {
            let parsedValue = inputValue.replace(/,/g, '.');
            this[propertyName] = parsedValue;
        },
    },
    mounted() {
        this.fetchProfiles();
    }
};
</script>

<style scoped>
.item-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 1031;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
}
</style>