<template>
    <div class="container pt-3">
        <span class="small" @click="goBack()" role="button"><i class="fa-solid fa-chevron-left me-2"></i>Tagasi</span>
        <table class="table mt-3 small">
            <thead>
                <tr>
                    <th scope="col">Aeg</th>
                    <th scope="col">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="flex-grow-1">{{ getColumnHeader(currentColumn) }}</span>
                            <i role="button" @click="showPreviousColumn" :disabled="currentColumn === 2" class="fa-solid fa-circle-arrow-left me-1"></i>
                            <i role="button" @click="showNextColumn" :disabled="currentColumn === totalColumns - 1" class="fa-solid fa-circle-arrow-right"></i>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="signal in signals" :key="signal.EventSummaryId">
                    <td>{{ signal.received }}</td>
                    <td class="text-break">{{ getColumnData(signal, currentColumn) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from '@/services/axios';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            signals: [],
            currentColumn: 2,
            totalColumns: 8,
        };
    },
    setup() {
        const route = useRoute();
        const projectId = route.params.projectId;
        const simsAccountId = route.params.simsAccountId;

        return { projectId, simsAccountId };
    },
    methods: {
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        async fetchSignals() {
            try {
                const response = await axios.get(`/projects/${this.projectId}/sims-accounts/${this.simsAccountId}/signals`);
                this.signals = response.data.data;
            } catch (error) {
                console.error('Error fetching signals:', error);
                this.$root.$refs.globalAlert.showAlert('Signaal puudub', 'danger');
            }
        },
        showNextColumn() {
            if (this.currentColumn < this.totalColumns - 1) {
                this.currentColumn++;
            }
        },
        showPreviousColumn() {
            if (this.currentColumn > 2) {
                this.currentColumn--;
            }
        },
        getColumnHeader(columnIndex) {
            const headers = ['', 'Aeg', 'Konto', 'Signaal', 'Tüüp', 'Kirjeldus', 'Dispositioon', 'Selgitus'];
            return headers[columnIndex];
        },
        getColumnData(signal, columnIndex) {
            const keys = ['id', 'received', 'account', 'zone', 'eventType', 'description', 'disposition', 'comment'];
            return signal[keys[columnIndex]];
        }
    },
    mounted() {
        this.fetchSignals();
    },
    created() {
        this.interval = setInterval(this.fetchSignals, 10000);
    },
    beforeUnmount() {
        clearInterval(this.interval); // Clear the interval when component is destroyed
    }
};
</script>
