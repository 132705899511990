<template>
    <div class="container">
        <div v-if="loading">
        </div>
        <div v-else>
            <div v-if="object">
                <div role="button" class="small" @click="goBack()"><i
                        class="fa-solid fa-chevron-left m-2 mt-3"></i>Tagasi
                </div>
                <h3 class="my-3">{{ object.name }}</h3>

                <div class="accordion shadow mt-3" id="accordionDescription">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseDescription" aria-expanded="false"
                                aria-controls="collapseDescription">
                                Kirjeldus
                            </button>
                        </h2>
                        <div id="collapseDescription" class="accordion-collapse collapse"
                            data-bs-parent="#accordionDescription">
                            <div class="accordion-body">
                                <div v-if="object.description" class="small text-multiline text-break">
                                    {{ object.description }}
                                </div>
                                <div v-else class="fst-italic small">
                                    Kirjeldus puudub
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionNotes">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseNotes" aria-expanded="false"
                                aria-controls="collapseNotes">
                                Märkmed
                            </button>
                        </h2>
                        <div id="collapseNotes" class="accordion-collapse collapse" data-bs-parent="#accordionNotes">
                            <div class="accordion-body">
                                <div v-if="object.notes" class="small text-multiline text-break">
                                    {{ object.notes }}
                                </div>
                                <div v-else class="fst-italic small">
                                    Märkmed puuduvad
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionNeedsAttention">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseNeedsAttention" aria-expanded="false"
                                aria-controls="collapseNeedsAttention">
                                Seadme puudused
                            </button>
                        </h2>
                        <div id="collapseNeedsAttention" class="accordion-collapse collapse"
                            data-bs-parent="#accordionNeedsAttention">
                            <div class="accordion-body">
                                <ul v-if="object.needs_attention.length > 0" class="list-group list-group-flush">
                                    <li v-for="item in object.needs_attention" :key="item.id"
                                        class="my-2 list-group-item">
                                        <router-link :to="`/tasks/${item.id}`"
                                            class="text-decoration-none d-flex justify-content-between align-items-start">
                                            <div>
                                                <div class="small fw-bolder">{{ item.name }}</div>
                                                <div class="small my-1">{{ item.notes }}</div>
                                                <div class="text-secondary"><small>{{ item.finished_by_name }} {{
            item.finished_on ? formatTimestamp(item.finished_on) : ''
        }}</small></div>
                                            </div>
                                            <div><i class="ms-2 fa-solid fa-triangle-exclamation"
                                                    :class="{ 'text-danger': item.needs_attention == 1, 'text-warning': item.needs_attention == 2, 'text-primary': item.needs_attention == 4 }"></i>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                                <div v-else class="fst-italic small">Puudused puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionFiles">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseFiles" aria-expanded="false"
                                aria-controls="collapseFiles">
                                Manused
                            </button>
                        </h2>
                        <div id="collapseFiles" class="accordion-collapse collapse" data-bs-parent="#accordionFiles">
                            <div class="accordion-body">
                                <ul v-if="object.files.length > 0" class="list-unstyled">
                                    <li class="my-2 list-group-item d-flex justify-content-between align-items-start"
                                        v-for="file in object.files" :key="file.id">
                                        <div class="btn btn-light w-100 text-start text-truncate"
                                            @click="requestFile(file)"><i
                                                class="fa-solid fa-paperclip me-2"></i><small>{{
            file.filename }}</small></div>
                                    </li>
                                </ul>
                                <div v-else class="fst-italic small">Manused puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div v-if="!object.has_guid" class="col text-center">
                        <button class="btn btn-primary" @click="openQRScanner">Seo QR'ga</button>
                    </div>
                    <div class="col text-center">
                        <router-link :to="{ name: 'ObjectTasks', params: { objectId: object.id } }">
                            <button class="btn btn-primary">Vaata seadme töid</button>
                        </router-link>
                    </div>
                </div>

                <div v-if="qrScannerVisible" class="qr-scanner-modal">
                    <div class="qr-scanner-content">
                        <qrcode-stream @detect="onDetect" @init="onInit" :formats="['qr_code']" />
                        <button @click="closeQRScanner" class="btn btn-primary mt-3">Sulge</button>
                    </div>
                </div>

            </div>
            <NotFound v-else :item="'seade'" />
        </div>
    </div>
</template>

<script>
import { useFormatTimestamp } from '@/composables/useFormatTimestamp';
import axios from '@/services/axios';
import NotFound from '@/components/NotFound.vue';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    data() {
        return {
            object: null,
            loading: true,
            qrScannerVisible: false,
        };
    },
    components: {
        NotFound,
        QrcodeStream
    },
    setup() {
        const { formatTimestamp } = useFormatTimestamp();
        return { formatTimestamp };
    },
    methods: {
        async fetchObject() {
            try {
                const objectId = this.$route.params.objectId;
                const response = await axios.get(`/objects/${objectId}`);
                this.object = response.data.data;
            } catch (error) {
                console.error('Error fetching object details:', error);
                // Optionally, handle the error (e.g., set an error message, redirect, etc.)
            } finally {
                this.loading = false;
            }
        },
        async requestFile(file) {
            try {
                const response = await axios({
                    url: `objects/${this.object.id}/files/${file.id}`, // API endpoint to get the file
                    method: 'GET',
                    responseType: 'blob', // Important for files
                });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link to download it
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        openQRScanner() {
            this.qrScannerVisible = true;
        },
        closeQRScanner() {
            this.qrScannerVisible = false;
        },
        onInit(promise) {
            promise.catch(error => {
                console.error('QR code reader initialization failed:', error);
            });
        },
        async onDetect(decodedArray) {
            try {
                console.log(decodedArray)
                if (decodedArray.length > 0 && decodedArray[0].rawValue) {
                    const url = new URL(decodedArray[0].rawValue);
                    const urlParams = new URLSearchParams(url.search);
                    const linkValue = urlParams.get('link');

                    if (linkValue) {
                        const objectId = this.$route.params.objectId;
                        await axios.post(`/objects/${objectId}/qr`, { link: linkValue });
                        this.fetchObject();
                    } else {
                        alert('Invalid QR code format.');
                    }
                }
            } catch (error) {
                console.error('Failed to process QR code:', error);
                alert('Failed to process the QR code. Please try again.');
            } finally {
                this.qrScannerVisible = false;
            }
        },
    },
    mounted() {
        this.fetchObject();
    }
};
</script>
<style scoped>
.qr-scanner-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.qr-scanner-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}
</style>