<template>
    <div class="container">
        <div v-if="loading">
        </div>
        <div v-else>
            <div v-if="project">
                <div role="button" class="small" @click="goBack()"><i
                        class="fa-solid fa-chevron-left m-2 mt-3"></i>Tagasi
                </div>
                <h3 class="my-3">{{ project.name }}</h3>

                <div class="accordion shadow mt-3" id="accordionNotes">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseNotes" aria-expanded="false"
                                aria-controls="collapseNotes">
                                Tehniline info
                            </button>
                        </h2>
                        <div id="collapseNotes" class="accordion-collapse collapse" data-bs-parent="#accordionNotes">
                            <div class="accordion-body">
                                <div v-if="project.memo4" class="small text-multiline text-break">
                                    {{ project.memo4 }}
                                </div>
                                <div v-else class="fst-italic small">
                                    Tehniline info puudub
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionLocation">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseLocation" aria-expanded="false"
                                aria-controls="collapseLocation">
                                Asukoht
                            </button>
                        </h2>
                        <div id="collapseLocation" class="accordion-collapse collapse"
                            data-bs-parent="#accordionLocation">
                            <div class="accordion-body text-multiline">
                                <div v-if="project.address">
                                    <div class="small my-3">Aadress: {{ project.address }}</div>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a :href="googleMapsUrl" target="_blank"
                                                class="btn btn-primary btn-sm w-100">
                                                Google Maps
                                            </a>
                                        </div>
                                        <div class="col-auto">
                                            <a :href="wazeUrl" target="_blank" class="btn btn-primary btn-sm w-100">
                                                Waze
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="project.coordinates">
                                    <div class="small my-3">Koordinaadid: {{ project.coordinates }}</div>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a :href="googleMapsUrlCoordinates" target="_blank"
                                                class="btn btn-primary btn-sm w-100">
                                                Google Maps
                                            </a>
                                        </div>
                                        <div class="col-auto">
                                            <a :href="wazeUrlCoordinates" target="_blank" class="btn btn-primary btn-sm w-100">
                                                Waze
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!project.address && !project.coordinates" class="small fst-italic">
                                    Asukoht puudub
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionFiles">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseFiles" aria-expanded="false"
                                aria-controls="collapseFiles">
                                Manused
                            </button>
                        </h2>
                        <div id="collapseFiles" class="accordion-collapse collapse" data-bs-parent="#accordionFiles">
                            <div class="accordion-body">
                                <ul v-if="project.files.length > 0" class="list-unstyled">
                                    <li class="my-2 list-group-item d-flex justify-content-between align-items-start"
                                        v-for="file in project.files" :key="file.id">
                                        <div class="btn btn-light w-100 text-start text-truncate" @click="requestFile(file)"><i class="fa-solid fa-paperclip me-2"></i><small>{{ file.filename }}</small></div>
                                    </li>
                                </ul>
                                <div v-else class="fst-italic small">Manused puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionContact">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseContact" aria-expanded="false"
                                aria-controls="collapseContact">
                                Kliendi kontaktandmed
                            </button>
                        </h2>
                        <div id="collapseContact" class="accordion-collapse collapse"
                            data-bs-parent="#accordionContact">
                            <div class="accordion-body">
                                <div v-if="project.customer_contacts.length > 0">
                                    <h6>Kliendi kontaktid</h6>
                                    <div v-for="contact in project.customer_contacts" :key="contact.id">
                                        <ul class="list-unstyled">
                                            <li>
                                                <i class="fa-regular fa-user text-danger"></i>
                                                <span class="ps-2"><small>{{ contact.name }}</small></span>
                                            </li>
                                            <li>
                                                <a :href="`tel:${contact.phone}`">
                                                    <i class="fa-solid fa-phone text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.phone }}</small></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="`mailto:${contact.email}`">
                                                    <i class="fa-regular fa-envelope text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.email }}</small></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else class="fst-italic small">Kontaktisikud puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion shadow mt-3" id="accordionProjectContact">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseProjectContact" aria-expanded="false"
                                aria-controls="collapseProjectContact">
                                Foruse kontaktandmed
                            </button>
                        </h2>
                        <div id="collapseProjectContact" class="accordion-collapse collapse"
                            data-bs-parent="#accordionProjectContact">
                            <div class="accordion-body">
                                <div v-if="project.contacts.length > 0">
                                    <div v-for="contact in project.contacts" :key="contact.id">
                                        <h6>{{ contact.title }}</h6>
                                        <ul class="list-unstyled">
                                            <li>
                                                <i class="fa-regular fa-user text-danger"></i>
                                                <span class="ps-2"><small>{{ contact.name }}</small></span>
                                            </li>
                                            <li>
                                                <a :href="`tel:${contact.phone}`">
                                                    <i class="fa-solid fa-phone text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.phone }}</small></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="`mailto:${contact.email}`">
                                                    <i class="fa-regular fa-envelope text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.email }}</small></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else class="fst-italic small">Kontaktisikud puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col text-center">
                        <router-link :to="{ name: 'ProjectObjects', params: { projectId: project.id }}">
                            <button class="btn btn-primary">Vaata seadmeid</button>
                        </router-link>
                    </div>
                    <div class="col text-center">
                        <router-link :to="{ name: 'ProjectTasks', params: { projectId: project.id }}">
                            <button class="btn btn-primary">Vaata objekti töid</button>
                        </router-link>
                    </div>
                </div>

            </div>
            <NotFound v-else :item="'objekt'" />
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import NotFound from '@/components/NotFound.vue';

export default {
    data() {
        return {
            project: null,
            loading: true
        };
    },
    components: {
        NotFound
    },
    methods: {
        async fetchProject() {
            try {
                const projectId = this.$route.params.projectId;
                const response = await axios.get(`/projects/${projectId}`);
                this.project = response.data.data;
            } catch (error) {
                console.error('Error fetching project details:', error);
                // Optionally, handle the error (e.g., set an error message, redirect, etc.)
            } finally {
                this.loading = false;
            }
        },
        async requestFile(file) {
            try {
                const response = await axios({
                    url: `projects/${this.project.id}/files/${file.id}`, // API endpoint to get the file
                    method: 'GET',
                    responseType: 'blob', // Important for files
                });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link to download it
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
    },
    computed: {
        googleMapsUrl() {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.project.address)}`;
        },
        wazeUrl() {
            return `https://waze.com/ul?q=${encodeURIComponent(this.project.address)}`;
        },
        googleMapsUrlCoordinates() {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.project.coordinates)}`;
        },
        wazeUrlCoordinates() {
            return `https://waze.com/ul?q=${encodeURIComponent(this.project.coordinates)}`;
        },
    },
    mounted() {
        this.fetchProject();
    }
};
</script>