import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import TaskDetail from '../views/TaskDetailView.vue';
import TestView from '@/views/TestView.vue';

import TasksListView from '../views/TasksListView.vue';
import TasksCriticalListView from '../views/TasksCriticalListView.vue';
import TasksTodayListView from '../views/TasksTodayListView.vue';
import TasksTomorrowListView from '../views/TasksTomorrowListView.vue';
import TasksUnfinishedListView from '../views/TasksUnfinishedListView.vue';
import TasksInProgressListView from '../views/TasksInProgressListView.vue';
import TasksOpenListView from '../views/TasksOpenListView.vue';
import TasksFinishedListView from '../views/TasksFinishedListView.vue';
import StockItemsView from '@/views/StockItemsView.vue';
import RealestatesListView from '@/views/RealestatesListView.vue';
import RealestateDetailView from '@/views/RealestateDetailView.vue';
import ProjectsListView from '@/views/ProjectsListView.vue';
import ProjectDetailView from '@/views/ProjectDetailView.vue';
import ObjectsListView from '@/views/ObjectsListView.vue';
import ObjectDetailView from '@/views/ObjectDetailView.vue';
import NotificationsView from '@/views/NotificationsView.vue';
import CalendarView from '@/views/CalendarView.vue';
import SimsAccountSignals from '@/views/SimsAccountSignals.vue';
import WorkloadView from '@/views/WorkloadView.vue';
import TaskActTemplate from '@/views/TaskActTemplate.vue';
import ScanQRCode from '@/views/ScanQRCode.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/tasks/:taskId',
        name: 'TaskDetail',
        component: TaskDetail,
        meta: { requiresAuth: true }
    },
    {
        path: '/test',
        name: 'Test',
        component: TestView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks',
        name: 'TasksList',
        component: TasksListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks/:id/act/:template_id',
        name: 'TaskActTemplate',
        component: TaskActTemplate,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-critical',
        name: 'TasksCritical',
        component: TasksCriticalListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-today',
        name: 'TasksToday',
        component: TasksTodayListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-tomorrow',
        name: 'TasksTomorrow',
        component: TasksTomorrowListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-unfinished',
        name: 'TasksUnfinished',
        component: TasksUnfinishedListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-inprogress',
        name: 'TasksInProgress',
        component: TasksInProgressListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-open',
        name: 'TasksOpen',
        component: TasksOpenListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/tasks-finished',
        name: 'TasksFinished',
        component: TasksFinishedListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/stock-items',
        name: 'StockItems',
        component: StockItemsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/realestates',
        name: 'RealestatesListView',
        component: RealestatesListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/realestates/:realestateId',
        name: 'RealestateDetail',
        component: RealestateDetailView,
        meta: { requiresAuth: true }
    },
    {
        path: '/projects',
        name: 'ProjectsListView',
        component: ProjectsListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/projects/:projectId',
        name: 'ProjectDetail',
        component: ProjectDetailView,
        meta: { requiresAuth: true }
    },
    {
        path: '/projects/:projectId/tasks',
        name: 'ProjectTasks',
        component: TasksListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/projects/:projectId/objects',
        name: 'ProjectObjects',
        component: ObjectsListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/notifications',
        name: 'NotificationsView',
        component: NotificationsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/objects',
        name: 'ObjectsListView',
        component: ObjectsListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/objects/:objectId',
        name: 'ObjectsDetail',
        component: ObjectDetailView,
        meta: { requiresAuth: true }
    },
    {
        path: '/objects/:objectId/tasks',
        name: 'ObjectTasks',
        component: TasksListView,
        meta: { requiresAuth: true }
    },
    {
        path: '/projects/:projectId/sims-accounts/:simsAccountId/signals',
        name: 'SimsAccountSignals',
        component: SimsAccountSignals,
        meta: { requiresAuth: true }
    },
    {
        path: '/calendar',
        name: 'CalendarView',
        component: CalendarView,
        meta: { requiresAuth: true }
    },
    {
        path: '/workload/:yearMonth?',
        name: 'WorkloadView',
        component: WorkloadView,
        meta: { requiresAuth: true },
        props: true
    },
    {
        path: '/scan',
        name: 'ScanQRCode',
        component: ScanQRCode,
        meta: { requiresAuth: true },
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = !!localStorage.getItem('token');
    to.meta.internalNavigation = true;

    if (to.meta.requiresAuth && !localStorage.getItem('token')) {
        next('/login');
    } else if (to.path === '/login' && isLoggedIn) {
        next('/');
    } else {
        next();
    }
});

export default router;
