<template>
    <div class="container">
        <h1 class="my-3">Objektikaardid</h1>
        <div class="input-group mb-3 rounded-pill">
            <input type="text" class="form-control" placeholder="Otsi objektikaarte" v-model="searchTerm" @keyup.enter="fetchProjects(1, searchTerm)">
            <span class="input-group-text bg-white" type="button" @click="fetchProjects(1, searchTerm)">
                <i class="fa fa-search"></i>
            </span>
        </div>
        <div v-if="projects.length > 0">
            <ul class="list-group mb-3">
                <li v-for="project in projects" :key="project.id" class="list-group-item p-3">
                    <router-link :to="`/projects/${project.id}`" class="text-decoration-none">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="fw-bolder small">{{ project.name }}</div>
                                <div class="small">{{ project.address }}</div>
                            </div>
                            <div class="col-auto text-end">
                                <button class="btn-icon"><i class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <ListPagination :currentPage="currentPage" :totalTasks="totalItems" :limit="limit"
                @page-changed="(page) => fetchProjects(page, searchTerm)" />
        </div>
        <div v-else>

        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import ListPagination from '@/components/ListPagination.vue';

export default {
    data() {
        return {
            projects: [],
            currentPage: 1,
            totalItems: 0,
            limit: 20,
            searchTerm: ''
        };
    },
    components: {
        ListPagination
    },
    methods: {
        async fetchProjects(page = 1, searchTerm = '') {
            try {
                const response = await axios.get(`/projects?not_active=0&page=${page}&limit=${this.limit}&search=${encodeURIComponent(searchTerm)}`);
                this.projects = response.data.data;
                this.totalItems = response.data.total;
                this.currentPage = page;
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        }
    },
    mounted() {
        this.fetchProjects();
    },
};
</script>