<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <img :src="require('@/assets/logo.png')" alt="logo" style="height: 40px;">
            </router-link>
        </div>
    </nav>
    <div class="text-center">
        <img class="img-fluid mb-3" :src="require('@/assets/login.png')" />
    </div>
    <div class="container">
        <h1 class="my-3">Sisene</h1>
        <div class="row my-3">
            <div class="col d-grid">
                <button class="btn btn-lg border-1 border-primary-subtle" @click="loginWithMicrosoft">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                        class="mssignin-logo">
                        <title>MS-SymbolLockup</title>
                        <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                        <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                        <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                        <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
                    </svg>
                    <span class="mssignin-label">Logi sisse Microsoftiga</span>
                </button>
            </div>
        </div>
        <div class="separator"><small>või</small></div>

        <form @submit.prevent="login">
            <div class="row mt-3">
                <div class="col">
                    <label for="username" class="small">Kasutajanimi</label>
                    <input v-model="username" type="text" class="form-control form-control-lg mt-2 fs-6 p-3"
                        id="username" placeholder="Kasutajanimi" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label for="password" class="small">Parool</label>
                    <input v-model="password" type="password" class="form-control form-control-lg mt-2 fs-6 p-3"
                        id="password" placeholder="Parool" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col d-grid">
                    <button type="submit" class="btn btn-lg btn-primary">Logi sisse</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from '@/services/axios';
import { msalInstance, initializeMsal } from '@/composables/msalConfig';

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post('login/', {
                    username: this.username,
                    password: this.password
                });
                const { token, user } = response.data.data;
                localStorage.setItem('token', token);
                localStorage.setItem('loginMethod', 'standard');
                localStorage.setItem('user', JSON.stringify(user));
                this.$router.push('/');
            } catch (error) {
                this.$root.$refs.globalAlert.showAlert('Login error', 'danger');
                console.error("Login error", error);
            }
        },
        async loginWithMicrosoft() {
            try {
                const loginRequest = {
                    scopes: ["User.Read"]
                };
                const response = await msalInstance.loginPopup(loginRequest);
                this.sendTokenToApi(response.accessToken);
            } catch (error) {
                this.$root.$refs.globalAlert.showAlert('Login with Microsoft error', 'danger');
                console.error("Login with Microsoft error", error);
            }
        },

        async sendTokenToApi(token) {
            try {
                const response = await axios.post('login/microsoft', { token });
                const { token: apiToken, user } = response.data.data;
                localStorage.setItem('token', apiToken);
                localStorage.setItem('loginMethod', 'microsoft');
                localStorage.setItem('user', JSON.stringify(user));
                this.$router.push('/');
            } catch (error) {
                console.error("Error sending token to API", error);
            }
        }
    },
    created() {
        initializeMsal().then(() => {
        }).catch(error => {
            console.error('Error initializing MSAL', error);
        });
    },
};
</script>

<style scoped>
.mssignin-button {
    display: inline-flex;
    align-items: center;
    border: 1px solid #8c8c8c;
    background: #ffffff;
    height: 41px;
    padding: 16px;
    cursor: pointer;
    width: 100%;
    border-radius: 40px;
}

.mssignin-logo {
    margin-right: 10px;
}

.mssignin-label {
    color: #5e5e5e;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #D0D4E3;
}

.separator:not(:empty)::before {
    margin-right: 1em;
}

.separator:not(:empty)::after {
    margin-left: 1em;
}
</style>