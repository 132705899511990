<template>
    <MainNavbar v-if="!isLoginPage" />
    <router-view />
    <div v-if="updateAvailable" class="update-notification text-bg-success">
      Tarkvarauuendus olemas. <button class="btn btn-sm btn-success" @click="refreshApp">Uuenda</button>
    </div>
    <global-alert ref="globalAlert"></global-alert>
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue';
import { ref, onMounted } from 'vue';

export default {
    name: 'ForusTech',
    components: {
        MainNavbar
    },
    computed: {
        isLoginPage() {
            return this.$route.name === 'login';
        }
    },
    setup() {
    const updateAvailable = ref(false);

    onMounted(() => {
      window.addEventListener('swUpdated', () => {
        updateAvailable.value = true;
      });
    });

    const refreshApp = () => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    };

    return {
      updateAvailable,
      refreshApp
    };
  }
};
</script>
<style>
.update-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}
</style>