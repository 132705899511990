import { format, parseISO } from 'date-fns';

export function useFormatTimestamp() {
  const formatTimestamp = (timestamp, includeTime = true) => {
    const date = parseISO(timestamp);
    if (includeTime) {
      return format(date, "dd.MM.yyyy 'kell' HH:mm");
    } else {
      return format(date, "dd.MM.yyyy");
    }
  };

  return { formatTimestamp };
}
