<template>
    <div class="container">
        <h1 class="mt-3">{{ title }}</h1>
        <div class="input-group mb-3 rounded-pill">
            <input type="text" class="form-control" placeholder="Otsi töid" v-model="searchTerm" @keyup.enter="fetchTasks(1, searchTerm)">
            <span class="input-group-text bg-white" type="button" @click="fetchTasks(1, searchTerm)">
                <i class="fa fa-search"></i>
            </span>
        </div>
        <TaskList :tasks="tasks" />
        <ListPagination :currentPage="currentPage" :totalTasks="totalTasks" :limit="limit" @page-changed="fetchTasks" />
    </div>
</template>
  
<script>
import axios from '@/services/axios';
import TaskList from '@/components/TaskList.vue';
import ListPagination from '@/components/ListPagination.vue';

export default {
    name: 'TasksListView',
    components: {
        TaskList,
        ListPagination
    },
    props: {
        title: String,
        apiUrl: String
    },
    data() {
        return {
            tasks: [],
            currentPage: 1,
            limit: 10,
            totalTasks: 0,
            searchTerm: ''
        };
    },
    mounted() {
        this.fetchTasks();
    },
    methods: {
        async fetchTasks(page = this.currentPage, searchTerm = '') {
            try {
                const response = await axios.get(`${this.apiUrl}&page=${page}&limit=${this.limit}&search=${encodeURIComponent(searchTerm)}`);
                this.tasks = response.data.data;
                this.totalTasks = response.data.total;
                this.currentPage = page;
            } catch (error) {
                console.error(`Error fetching tasks for ${this.title}:`, error);
            }
        }
    }
};
</script>
  
<style scoped>
/* Your styles here */
</style>
  