<template>
    <div>
        <label>{{ label }}</label>
        <input type="time" :name="name" :value="localValue" @input="onInput" :placeholder="placeholder"
            class="input-inline" />
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            localValue: this.value
        };
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
        }
    },
    methods: {
        onInput(event) {
            this.localValue = event.target.value;
            this.$emit('update:value', event.target.value);
        }
    }
};
</script>

<style scoped>
.input-inline {
    margin-right: 5px;
}
</style>