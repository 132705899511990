<template>
    <textarea :name="name" :value="localValue" @input="onInput" :placeholder="placeholder"
        class="input-wide"></textarea>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            localValue: this.value
        };
    },
    watch: {
        value(newVal) {
            this.localValue = newVal;
        }
    },
    methods: {
        onInput(event) {
            this.localValue = event.target.value;
            this.$emit('update:value', event.target.value);
        }
    }
};
</script>

<style scoped>
.input-wide {
    width: 100%;
    min-height: 100px;
}
</style>