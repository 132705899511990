<template>
    <div class="container">
        <div v-if="loading">
        </div>
        <div v-else>
            <div v-if="realestate">
                <div role="button" class="small" @click="goBack()"><i
                        class="fa-solid fa-chevron-left m-2 mt-3"></i>Tagasi
                </div>
                <h3 class="my-3">{{ realestate.name }}</h3>

                <div class="accordion shadow mt-3" id="accordionProjects">
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseProjects" aria-expanded="false"
                                aria-controls="collapseProjects">
                                Objektikaardid
                            </button>
                        </h2>
                        <div id="collapseProjects" class="accordion-collapse collapse"
                            data-bs-parent="#accordionProjects">
                            <div class="accordion-body">
                                <ul class="list-group list-group-flush" v-if="realestate.projects.length > 0">
                                    <router-link v-for="project in realestate.projects" :key="project.id" :to="`/projects/${project.id}`" class="list-group-item list-group-item-action">{{ project.name }}</router-link>
                                </ul>
                                <div v-else class="fst-italic small">Kontaktisikud puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <NotFound v-else :item="'hoone'" />
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import NotFound from '@/components/NotFound.vue';

export default {
    data() {
        return {
            realestate: null,
            loading: true
        };
    },
    components: {
        NotFound
    },
    methods: {
        async fetchRealestate() {
            try {
                const realestateId = this.$route.params.realestateId;
                const response = await axios.get(`/realestates/${realestateId}`);
                this.realestate = response.data.data;
            } catch (error) {
                console.error('Error fetching realestate details:', error);
                // Optionally, handle the error (e.g., set an error message, redirect, etc.)
            } finally {
                this.loading = false;
            }
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
    },
    mounted() {
        this.fetchRealestate();
    }
};
</script>