<template>
    <div class="container">
        <span @click="$emit('close')" role="button"><small><i
                    class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h2 class="my-3">{{ type === 'materials' ? 'Lisa materjal ja seadme müük' : 'Lisa aeg või lisatöö' }}</h2>

        <div class="mb-3">
            <label for="resourceSelect" class="form-label"><small>{{ type === 'materials' ? 'Materjal' : 'Töö'
                    }}</small></label>
            <select v-if="!itemToEdit" id="resourceSelect" v-model="selectedResource" class="form-select bg-light">
                <option v-for="resource in resources" :key="resource.id" :value="resource.id">
                    {{ resource.name }}
                </option>
            </select>
            <div class="form-select bg-secondary-subtle" v-else>{{ itemToEdit.resource_name }}</div>
        </div>

        <div class="mb-3">
            <label for="profileSelect" class="form-label"><small>Töö liik</small></label>
            <select id="profileSelect" v-model="selectedProfile" class="form-select bg-light">
                <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
                    {{ profile.name }}
                </option>
            </select>
        </div>

        <div v-if="type === 'materials'" class="mb-3">
            <label for="serialNoInput" class="form-label"><small>SN</small></label>
            <div class="input-group">

                <input type="text" v-model="serial_no" id="serialNoInput" class="form-control bg-light">
                <span role="button" class="input-group-text bg-primary text-light"
                    @click="showScanner = !showScanner"><i class="fa-solid fa-camera"></i></span>
            </div>
        </div>

        <div v-if="showScanner">
            <qrcode-stream @detect="onDetect" @init="onInit"
                :formats="['qr_code', 'code_128', 'ean_8', 'ean_13']"></qrcode-stream>
            <div v-if="error" class="error">{{ error }}</div>
        </div>

        <div class="mb-3">
            <label for="descriptionInput" class="form-label"><small>Kirjeldus</small></label>
            <textarea id="descriptionInput" class="form-control bg-light" v-model="description"
                placeholder="Kirjeldus"></textarea>
        </div>

        <div class="mb-3">
            <label for="amountInput" class="form-label"><small>{{ type === 'materials' ? 'Kogus' : 'Tunnid' }}</small></label>
            <input id="amountInput" class="form-control bg-light"
                :class="{ 'bg-secondary-subtle': itemToEdit && type === 'materials' }" v-model="amount"
                placeholder="{{ type === 'materials' ? 'Kogus' : 'Tunnid' }}" type="text" inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('amount', $event.target.value)"
                :disabled="itemToEdit && type === 'materials'" />
        </div>

        <div v-if="type === 'hours'" class="mb-3">
            <label for="pointsInput" class="form-label"><small>Punktid</small></label>
            <input id="pointsInput" class="form-control bg-light" v-model="points" placeholder="Punktid" type="text"
                inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('points', $event.target.value)" />
        </div>

        <div v-if="type === 'hours'" class="mb-3">
            <label for="distanceInput" class="form-label"><small>Kilomeetrid</small></label>
            <input id="distanceInput" class="form-control bg-light" v-model="distance" placeholder="Kilomeetrid" type="text"
                inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('distance', $event.target.value)" />
        </div>

        <div class="mb-3" v-if="isCustomResource">
            <label for="priceInput" class="form-label"><small>Hind</small></label>
            <input v-model="price" id="priceInput" class="form-control bg-light" placeholder="Hind" type="text"
                inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('price', $event.target.value)">
        </div>

        <div class="row">
            <div class="col">
                <div class="mb-3 form-check">
                    <input type="checkbox" v-model="accountingStatusId" class="form-check-input"
                        id="accountingCheckbox">
                    <label class="form-check-label" for="accountingCheckbox">Lisatöö</label>
                </div>
            </div>
            <div class="col text-end">
                <span v-if="this.itemToEdit" role="button" @click="deleteItem" class="text-danger"><small><i
                            class="fa-solid fa-trash-can me-2"></i>Kustuta</small></span>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col">
                <button @click="$emit('close')" class="btn border-1 border-primary text-primary w-100">Loobu</button>
            </div>
            <div class="col">
                <button v-if="!this.itemToEdit" @click="submitItem" class="btn btn-primary w-100">Salvesta</button>
                <button v-if="this.itemToEdit" @click="saveChanges" class="btn btn-primary w-100">Salvesta</button>
            </div>
        </div>

    </div>
</template>

<script>

import axios from '@/services/axios';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    components: {
        QrcodeStream,
    },

    props: {
        taskId: {
            type: Number,
            required: true
        },
        profileId: {
            type: Number,
            required: true
        },
        priorityId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        itemToEdit: {
            type: Object,
            default: () => ({})
        },
    },

    data() {
        return {
            selectedResource: null,
            selectedProfile: this.profileId,
            amount: 1,
            distance: null,
            price: null,
            points: null,
            serial_no: '',
            description: null,
            accountingStatusId: (this.priorityId == 2),
            isCustomResource: false,
            resources: [],
            profiles: [],
            showScanner: false,
            error: null,
        };
    },
    watch: {
        selectedResource(newValue) {
            const resource = this.resources.find(r => r.id === newValue);
            if (resource && resource.is_custom_resource) {
                this.isCustomResource = true;
            } else {
                this.isCustomResource = false;
            }
        },
    },
    mounted() {
        this.fetchResources().then(() => {
            this.fetchProfiles().then(() => {
                if (this.itemToEdit) {
                    this.setEditData();
                }
            });
        });
    },
    methods: {
        validateAmountInput(propertyName, inputValue) {
            let parsedValue = inputValue.replace(/,/g, '.');
            this[propertyName] = parsedValue;
        },
        onDetect(decodedArray) {
            if (decodedArray.length > 0 && decodedArray[0].rawValue) {
                this.serial_no = decodedArray[0].rawValue;
                this.showScanner = false;
            } else {
                console.error("No valid data found in the decoded array");
            }
        },

        onInit(promise) {
            promise.catch(error => {
                this.errorHandling(error);
            });
        },
        errorHandling(error) {
            this.showScanner = false;
            this.error = 'Scanning error: ' + error.message;
        },
        async fetchResources() {
            if (this.type === 'materials') {
                try {
                    const response = await axios.get(`/users/${this.currentUser.id}/stock-items`);
                    this.resources = response.data.data;
                } catch (error) {
                    console.error('Error fetching stock items:', error);
                }
            } else {
                try {
                    const response = await axios.get(`/resources?is_material=0&not_active=0`);
                    this.resources = response.data.data;
                } catch (error) {
                    console.error('Error fetching resources:', error);
                }
            }
        },
        async fetchProfiles() {
            try {
                const response = await axios.get(`/profiles?not_active=0`);
                this.profiles = response.data.data;
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        },
        setEditData() {
            this.selectedResource = this.itemToEdit.resource_id;
            this.selectedProfile = this.itemToEdit.profile_id;
            this.amount = this.itemToEdit.amount;
            this.points = this.itemToEdit.points;
            this.distance = this.itemToEdit.distance;
            this.price = this.itemToEdit.price;
            this.description = this.itemToEdit.description;
            this.accountingStatusId = this.itemToEdit.accountingstatus_id;
            this.serial_no = this.itemToEdit.serial_no;

            const resource = this.resources.find(r => r.id === this.selectedResource);
            this.isCustomResource = resource ? resource.is_custom_resource : false;
        },
        async submitItem() {
            try {
                const payload = {
                    resource_id: this.selectedResource,
                    profile_id: this.selectedProfile,
                    amount: parseFloat(this.amount),
                    points: parseFloat(this.points),
                    distance: parseFloat(this.distance),
                    description: this.description,
                    price: this.isCustomResource ? parseFloat(this.price) : null,
                    accountingstatus_id: this.accountingStatusId ? true : false,
                    serial_no: this.serial_no,
                };

                const response = await axios.post(`/tasks/${this.taskId}/resources`, payload);

                if (response.status === 200) {
                    this.$emit('item-added', response.data.data);
                    this.resetModalFields();
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error saving item:', error);
                this.$root.$refs.globalAlert.showAlert('Lisamine ebaõnnestus', 'danger');
            }
        },
        async saveChanges() {
            const payload = {
                resource_id: this.selectedResource,
                profile_id: this.selectedProfile,
                amount: this.amount,
                points: this.points,
                distance: this.distance,
                description: this.description,
                price: this.isCustomResource ? this.price : null,
                accountingstatus_id: this.accountingStatusId ? true : false,
                serial_no: this.serial_no,
            };

            try {
                const response = await axios.patch(`/tasks/${this.taskId}/resources/${this.itemToEdit.id}`, payload);
                if (response.status === 200) {
                    this.$emit('item-updated', response.data.data);
                    this.resetModalFields();
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error updating item:', error);
            }
        },
        async deleteItem() {
            try {
                await axios.delete(`/tasks/${this.taskId}/resources/${this.itemToEdit.id}`);
                this.$emit('item-deleted', this.itemToEdit.id);
                this.$emit('close');
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        },
        resetModalFields() {
            this.selectedResource = null;
            this.selectedProfile = null;
            this.amount = 1;
            this.points = null;
            this.distance = null;
            this.price = null;
            this.description = null;
            this.accountingStatusId = false;
            this.serial_no = '';
        }
    },
    computed: {
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    },
};
</script>

<style scoped></style>
