<template>
    <div class="container">
        <h1 class="my-3">Objektid</h1>
        <div class="input-group mb-3 rounded-pill">
            <input type="text" class="form-control" placeholder="Otsi seadmeid" v-model="searchTerm" @keyup.enter="fetchObjects(1, searchTerm)">
            <span class="input-group-text bg-white" type="button" @click="fetchObjects(1, searchTerm)">
                <i class="fa fa-search"></i>
            </span>
        </div>
        <div v-if="objects.length > 0">
            <ul class="list-group mb-3">
                <li v-for="object in objects" :key="object.id" class="list-group-item p-3">
                    <router-link :to="`/objects/${object.id}`" class="text-decoration-none">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="fw-bolder small">{{ object.name }}</div>
                                <div class="small">{{ object.project_name }}</div>
                            </div>
                            <div class="col-auto text-end">
                                <button class="btn-icon"><i class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <ListPagination :currentPage="currentPage" :totalTasks="totalItems" :limit="limit"
                @page-changed="(page) => fetchObjects(page, searchTerm)" />
        </div>
        <div v-else>

        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import ListPagination from '@/components/ListPagination.vue';

export default {
    data() {
        return {
            objects: [],
            currentPage: 1,
            totalItems: 0,
            limit: 20,
            searchTerm: ''
        };
    },
    components: {
        ListPagination
    },
    methods: {
        async fetchObjects(page = 1, searchTerm = '') {
            try {

                const params = new URLSearchParams({

                });

                const projectId = this.$route.params.projectId;

                if (projectId) {
                    params.append('project_id', projectId);
                }



                const response = await axios.get(`/objects?${params.toString()}&not_active=0&page=${page}&limit=${this.limit}&search=${encodeURIComponent(searchTerm)}`);
                this.objects = response.data.data;
                this.totalItems = response.data.total;
                this.currentPage = page;
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        }
    },
    mounted() {
        this.fetchObjects();
    },
};
</script>