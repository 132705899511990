<template>
  <div>
    <input
      type="checkbox"
      :name="name"
      :checked="localValue"
      @change="onChange"
      class="input-inline"
    />
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: this.value !== undefined ? this.value : false
    };
  },
  watch: {
    value(newVal) {
      this.localValue = newVal !== undefined ? newVal : false;
    }
  },
  methods: {
    onChange(event) {
      this.localValue = event.target.checked;
      this.$emit('update:value', event.target.checked);
    }
  }
};
</script>

<style scoped>
.input-inline {
  margin-right: 5px;
}
</style>
