<template>
    <div class="container mb-3">
        <div class="row justify-content-center align-items-center">

            <div class="col stage text-center">
                <div role="button" class="stage-icon" :class="{ 'completed': currentStage > 0 || finished, 'active': selectedStage === 0 }" @click="selectStage(0)">
                    <i v-if="currentStage > 0 || finished" class="fa fa-check"></i> <!-- Checkmark icon -->
                    <span v-else>1</span>
                </div>
            </div>

            <div class="col-auto line" v-if="totalStages > 1"></div>


            <div role="button" class="col stage text-center" v-if="totalStages > 1">
                <div class="stage-icon" :class="{ 'completed': currentStage > 1 || finished, 'active': selectedStage === 1 }" @click="selectStage(1)">
                    <i v-if="currentStage > 1 || finished" class="fa fa-check"></i> <!-- Checkmark icon -->
                    <span v-else>2</span>
                </div>
            </div>

            <div class="col-auto line" v-if="totalStages > 2"></div>


            <div role="button" class="col stage text-center" v-if="totalStages > 2">
                <div class="stage-icon" :class="{ 'completed': currentStage > 2 || finished, 'active': selectedStage === 2 }" @click="selectStage(2)">
                    <i v-if="currentStage > 2 || finished" class="fa fa-check"></i> <!-- Checkmark icon -->
                    <span v-else>3</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        currentStage: Number,
        totalStages: Number,
        selectedStage: Number,
        finished: Boolean
    },
    methods: {
        selectStage(stage) {
            this.$emit('stage-selected', stage);
        }
    }
};
</script>
  
  
<style scoped>
.stage-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 2px solid #BFC5D9;
    color: #2F174C;
    background-color: #BFC5D9;
}

.stage-icon.completed {
    background-color: #BFC5D9;
    border-color: #BFC5D9;
    color: #2F174C;
}

.stage-icon.active {
    background-color: #2F174C;
    border-color: #2F174C;
    color: #fff;
}

.line {
    flex-grow: 1;
    height: 2px;
    background-color: #BFC5D9;
}
</style>

  