<template>
    <TasksListComponent title="Lõpetatud tööd" :apiUrl="apiUrl" />
</template>
  
<script>
import TasksListComponent from '@/components/TasksListComponent.vue';

export default {
    name: 'TasksFinishedListView',
    components: {
        TasksListComponent
    },
    computed: {
        apiUrl() {
            const baseApiUrl = 'tasks/';
            const params = new URLSearchParams({
                finished: '1',
                users: this.currentUser.id,
                sort_by: 'finished_on',
                sort_order: 'desc'
            });
            return `${baseApiUrl}?${params.toString()}`;
        },
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    }
};
</script>
  