<template>
    <div class="alert alert-secondary mt-3">
        <h6>404</h6>
        <p>{{ message }}</p>
        <router-link to="/">Mine tagasi avalehele</router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    props: {
        item: String,
    },
    computed: {
        message() {
            return `Otsitav ${this.item || 'üksus'} ei eksisteeri või see võib olla eemaldatud.`;
        }
    }
};
</script>

<style>

</style>