<template>
    <router-link class="text-decoration-none" :to="link">
    <div class="frame">
        <div class="number-and-text">
            <div :class="['number', numberClass]">
                <div class="secondary-headline">{{ taskCount }}</div>
            </div>
            <div class="text">
                <div class="text-wrapper">{{ headline }}</div>
                <div class="label-text">{{ description }}</div>
            </div>
        </div>
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.8933 15.9929C23.8299 15.8293 23.7347 15.6797 23.6133 15.5529L16.9467 8.88626C16.8223 8.76194 16.6748 8.66333 16.5123 8.59605C16.3499 8.52877 16.1758 8.49414 16 8.49414C15.6449 8.49414 15.3044 8.63519 15.0533 8.88626C14.929 9.01058 14.8304 9.15817 14.7631 9.3206C14.6958 9.48303 14.6612 9.65712 14.6612 9.83293C14.6612 10.188 14.8023 10.5285 15.0533 10.7796L19.4533 15.1663H9.33333C8.97971 15.1663 8.64057 15.3067 8.39052 15.5568C8.14048 15.8068 8 16.146 8 16.4996C8 16.8532 8.14048 17.1924 8.39052 17.4424C8.64057 17.6925 8.97971 17.8329 9.33333 17.8329H19.4533L15.0533 22.2196C14.9284 22.3435 14.8292 22.491 14.7615 22.6535C14.6938 22.816 14.6589 22.9902 14.6589 23.1663C14.6589 23.3423 14.6938 23.5166 14.7615 23.679C14.8292 23.8415 14.9284 23.989 15.0533 24.1129C15.1773 24.2379 15.3248 24.3371 15.4872 24.4048C15.6497 24.4725 15.824 24.5073 16 24.5073C16.176 24.5073 16.3503 24.4725 16.5128 24.4048C16.6752 24.3371 16.8227 24.2379 16.9467 24.1129L23.6133 17.4463C23.7347 17.3195 23.8299 17.1699 23.8933 17.0063C24.0267 16.6816 24.0267 16.3175 23.8933 15.9929Z"
                fill="#2F174C" />
        </svg>
    </div>
    </router-link>
</template>
  
<script>
export default {
    props: {
        taskCount: Number,
        headline: String,
        description: String,
        link: String,
        numberClass: String
    }
}
</script>
  
<style scoped>
.frame {
    align-items: center;
    background-color: #f2f5fa;
    border: 1px solid;
    border-color: #d0d4e3b2;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    margin-top: 16px;
}

.frame .number-and-text {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.div-red {
    background-color: #ffe0e0;
    border: 1.04px solid;
    border-color: #c91235;
    border-radius: 8px;

}

.div-red .secondary-headline {
    color: #c91235;
}

.div-green {
    background-color: #E2F3EE;
    border: 1.04px solid;
    border-color: #30735F;
    border-radius: 8px;

}

.div-green .secondary-headline {
    color: #30735F;
}

.div-yellow {
    background-color: #FAEEDB;
    border: 1.04px solid;
    border-color: #A95606;
    border-radius: 8px;

}

.div-yellow .secondary-headline {
    color: #A95606;
}

.div-purple {
    background-color: #E2E9F4;
    border: 1.04px solid;
    border-color: #2F174C;
    border-radius: 8px;

}

.div-purple .secondary-headline {
    color: #2F174C;
}

.frame .number {
    height: 50px;
    line-height: 50px;
    position: relative;
    width: 50px;
}

.frame .secondary-headline {
    font-family: "Fira Sans-Medium", Helvetica;
    font-size: 26px;
    font-weight: 500;
    height: 50px;
    left: 0;
    letter-spacing: 0;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 50px;
}

.frame .t-ext {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 225px;
}

.frame .text-wrapper {
    color: #2f174c;
    font-family: "Fira Sans-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.frame .label-text {
    color: #333f4c;
    font-family: "Fira Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.frame .right-arrow {
    height: 32px;
    margin-left: -12px;
    position: relative;
    width: 32px;
}</style>