import axios from 'axios';
import router from '@/router';
import { msalInstance } from '@/composables/msalConfig';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    const loginMethod = localStorage.getItem('loginMethod');
    if (loginMethod === 'microsoft') {
        try {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                const silentRequest = {
                    scopes: ["User.Read"],
                    account: msalInstance.getAllAccounts()[0]
                };
                const response = await msalInstance.acquireTokenSilent(silentRequest);
                config.headers['X-Microsoft-Token'] = `Bearer ${response.accessToken}`;
            }
        } catch (error) {
            console.error("Error refreshing Microsoft token", error);
        }
    }

    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('loginMethod');
            localStorage.removeItem('user');
            router.push('/login');

            return Promise.reject(error);
        }
    }
);

export default axios;