import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "ac8541de-8db4-4f7f-80f2-360f684b5653",
        authority: "https://login.microsoftonline.com/5f111886-6e56-4e7b-b275-02db409b164d",
        redirectUri: window.location.origin + '/login',
    },
    system: {
        allowNativeBroker: true,
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsal() {
    await msalInstance.initialize();
}

export { msalInstance, initializeMsal };
