<template>
    <h3 class="mt-3">Valved</h3>
    <div v-if="groupedOnCallList && Object.keys(groupedOnCallList).length > 0">
        <div v-for="(items, groupName) in groupedOnCallList" :key="groupName">
            <h6 class="mt-3">{{ groupName }}:</h6>
            <ul class="list-unstyled">
                <li v-for="item in items" :key="item.id" class="">
                    <small>
                        {{ item.name }},
                        <a class="text-danger" :href="`tel:${item.phone}`">{{ item.phone }}</a>,
                        {{ item.period }}
                        ({{ item.region_name }})
                    </small>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import { useDate } from '@/composables/useDate';

export default {
    data() {
        return {
            groupedOnCallList: {}
        };
    },
    async mounted() {
        try {
            const { formatDate } = useDate();
            const formattedDate = formatDate(new Date());
            const response = await axios.get(`/on-call/?estimated_date_start=${formattedDate}&estimated_date_end=${formattedDate}`);
            this.groupedOnCallList = this.groupDataByProfileGroup(response.data.data);
        } catch (error) {
            console.error('Error fetching on-call data:', error);
        }
    },
    methods: {
        groupDataByProfileGroup(data) {
            const grouped = {};
            data.forEach(item => {
                if (!grouped[item.profile_group_name]) {
                    grouped[item.profile_group_name] = [];
                }
                grouped[item.profile_group_name].push(item);
            });
            return grouped;
        }
    }
};
</script>