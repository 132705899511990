<!-- ItemModal.vue -->
<template>
    <div v-if="show" class="item-modal bg-light">
        <div class="modal-content">
            <div class="container">
                <span @click="closeModal" role="button">
                    <small><i class="fa-solid fa-chevron-left me-2"></i>Tagasi</small>
                </span>
                <ul v-for="(group, key) in items" :key="group.id" class="list-group list-group-flush mt-3">
                    <h5>{{ key }}</h5>
                    <li v-for="item in group" :key="item.id" class="list-group-item">
                        <router-link v-if="item.id" :to="`/tasks/${item.id}`" class="text-decoration-none text-dark">
                            <div class="row">
                                <div class="col fw-medium">{{ item.date }}</div>
                                <div class="col text-end fw-medium">{{ parseFloat(item.amount) }}</div>
                            </div>
                            <div class="small">{{ item.name }}</div>
                        </router-link>
                        <div v-else>
                            <div class="row">
                                <div class="col fw-medium">{{ item.date }}</div>
                                <div class="col text-end fw-medium">{{ parseFloat(item.amount) }}</div>
                            </div>
                            <div class="small">{{ item.name }}</div>
                        </div>
                    </li>
                </ul>
                <button @click="closeModal" class="btn border-1 border-primary text-primary mt-3 w-100">Sulge</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.item-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 1031;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
}
</style>
