<template>
    <div class="container">
        <div class="card shadow">
            <div class="card-body">
                <span class="badge rounded-pill bg-warning-subtle text-warning">Ootel</span>
                <span class="badge rounded-pill text-bg-light">Hooldus</span>
                <span class="badge rounded-pill text-bg-light">65215157</span>
                <h5 class="mt-3">Küte ei tööta</h5>
                <img :src="require('@/assets/ico-location.svg')"><small>Solaris Keskus / Estonia puiestee 9, 10143
                    Tallinn</small>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">Planeeritud:</div>
                    <div class="col text-end">
                        <span class="badge rounded-pill bg-success-subtle text-success">08.02.2024 kell 12:00</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">Tähtaeg:</div>
                    <div class="col text-end">
                        <span class="badge rounded-pill text-bg-light">15.02.2024</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">Kliendi kontakt:</div>
                </div>
                <div class="row">
                    <div class="col">
                        <span>Kalle Kaasikas</span> <span class="text-danger">+372 59 30 2330</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <span class="badge rounded-pill bg-danger-subtle text-danger fw-normal w-100 text-start">
                            <img :src="require('@/assets/ico-exclamation-mark.svg')">
                            <span class="align-middle"> Objektiga on seotud aktiivne meeldetuletus.</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="badge bg-danger-subtle border border-danger text-danger border-1 fs-4 px-0"
            style="width: 50px; height: 50px;">
            <span class="align-middle">2</span>
        </div>
        <div class="badge bg-success-subtle border border-success text-success border-1 fs-4 px-0"
            style="width: 50px; height: 50px;">
            <span class="align-middle">4</span>
        </div>
        <div class="badge bg-success-subtle border border-success text-success border-1 fs-4 px-0"
            style="width: 50px; height: 50px;">
            <span class="align-middle">4</span>
        </div>
        <div class="badge bg-warning-subtle border border-warning text-warning border-1 fs-4 px-0"
            style="width: 50px; height: 50px;">
            <span class="align-middle">4</span>
        </div>
        <span class="badge text-bg-success">1</span>
        <span class="badge text-bg-success">1</span>
        <span class="badge text-bg-warning">1</span>
        <span class="badge text-bg-primary">1</span>
        <h1>Tere, Kalle!</h1>
        <div class="container">
            <button class="btn btn-primary">asd</button>
        </div>
    </div>
</template>
  
<script>
export default {
    // No need for props here
};
</script>
  
<style scoped>
/* Error view styles */
</style>