<template>
    <div class="container">
        <h1 class="my-3">QR</h1>
        <div v-if="showScanner">
            <qrcode-stream @detect="onDetect" @init="onInit" :formats="['qr_code']" />
        </div>
        <div v-else-if="message">
            <p>{{ message }}</p>
            <span role="button" class="btn btn-primary" @click="showScanner = !showScanner"><i
            class="fa-solid fa-camera"></i></span>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import axios from 'axios';

export default {
    components: {
        QrcodeStream
    },
    data() {
        return {
            showScanner: true,
            message: 's',
        };
    },
    methods: {
        onInit(promise) {
            promise.catch(error => {
                if (error.name === 'NotAllowedError') {
                    this.message = 'Camera access was denied. Please allow camera access to scan the QR code.';
                } else if (error.name === 'NotFoundError') {
                    this.message = 'No camera found. Please connect a camera and try again.';
                } else if (error.name === 'NotSupportedError') {
                    this.message = 'QR code scanning is not supported on this device.';
                } else if (error.name === 'NotReadableError') {
                    this.message = 'Cannot access your camera. It might be already in use by another application.';
                } else {
                    this.message = 'Error initializing QR code reader: ' + error.message;
                }
            });
        },
        async onDetect(decodedArray) {
            this.showScanner = false;
            try {
                if (decodedArray.length > 0 && decodedArray[0].rawValue) {
                    const url = new URL(decodedArray[0].rawValue);
                    const urlParams = new URLSearchParams(url.search);
                    const linkValue = urlParams.get('link');

                    if (linkValue) {
                        const response = await axios.get(`/scan/${linkValue}`);

                        const data = response.data.data;

                        if (data.object_id) {
                            this.$router.push(`/objects/${data.object_id}`);
                        } else {
                            this.message = 'Antud kood ei ole ühegi seadmega seotud';
                        }
                    } else {
                        this.message = 'Invalid QR code format';
                    }
                } else {
                    console.error("No valid data found in the decoded array");
                }

            } catch (err) {
                console.error('Failed to process QR code:', err);
                this.message = 'Failed to process the QR code. Please try again.';
            }
        },
    },
};
</script>

<style scoped>
</style>