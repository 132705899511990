<template>
    <TasksListComponent title="Homsed tööd" :apiUrl="apiUrl" />
</template>
  
<script>
import TasksListComponent from '@/components/TasksListComponent.vue';
import { useDate } from '@/composables/useDate';

export default {
    name: 'TasksTodayListView',
    components: {
        TasksListComponent
    },
    computed: {
        apiUrl() {
            const baseApiUrl = 'tasks/';

            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            const { formatDate } = useDate();
            const formattedDate = formatDate(tomorrow);

            const params = new URLSearchParams({
                finished: '0',
                users: this.currentUser.id,
                estimated_date_start: formattedDate,
                estimated_date_end: formattedDate,
                sort_by: 'estimated_date',
                sort_order: 'asc'
            });
            return `${baseApiUrl}?${params.toString()}`;
        },
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    }
};
</script>
  