<template>
    <div v-for="(alert, index) in alerts" :key="index" :class="['alert', 'alert-dismissible', `alert-${alert.type}`]"
        :style="getAlertStyle(index)" role="alert">
        <i class="fa-solid fa-circle-info"></i>
        {{ alert.message }}
        <button type="button" class="btn-close" @click="removeAlert(index)" aria-label="Close"></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            alerts: []
        };
    },
    methods: {
        showAlert(message, type = 'success') {
            this.alerts.unshift({ message, type });

            if (this.alerts.length > 5) {
                this.alerts.pop();
            }

            setTimeout(() => {
                this.alerts.pop();
            }, 3000);
        },
        removeAlert(index) {
            this.alerts.splice(index, 1);
        }
    },
    computed: {
        getAlertStyle() {
            return (index) => ({
                position: 'fixed',
                bottom: `${1 + index}rem`,
                left: '32px',
                right: '32px',
                margin: '0 auto',
                'z-index': '1032',
            });
        }
    }
};
</script>