<template>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <!-- Previous Page Arrow -->
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link bg-transparent" href="#" aria-label="Previous" @click="changePage(currentPage - 1)">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </li>

            <li v-if="showFirstPage" class="page-item">
                <a class="page-link" href="#" @click="changePage(1)">1</a>
            </li>

            <li class="page-item disabled" v-if="showFirstEllipsis">
                <span class="page-link"><i class="fa-solid fa-ellipsis"></i></span>
            </li>

            <li class="page-item" v-if="showPreviousPage">
                <a class="page-link" href="#" @click="changePage(currentPage - 1)">{{ currentPage - 1 }}</a>
            </li>

            <li class="page-item active">
                <span class="page-link">{{ currentPage }}</span>
            </li>

            <li class="page-item" v-if="showNextPage">
                <a class="page-link" href="#" @click="changePage(currentPage + 1)">{{ currentPage + 1 }}</a>
            </li>

            <li class="page-item disabled" v-if="showLastEllipsis">
                <span class="page-link"><i class="fa-solid fa-ellipsis"></i></span>
            </li>

            <li v-if="showLastPage" class="page-item">
                <a class="page-link" href="#" @click="changePage(totalPages)">{{ totalPages }}</a>
            </li>

            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link bg-transparent" href="#" aria-label="Next" @click="changePage(currentPage + 1)">
                    <i class="fa-solid fa-chevron-right"></i>
                </a>
            </li>
        </ul>
    </nav>
</template>
  

<script>
export default {
    props: {
        currentPage: Number,
        totalTasks: Number,
        limit: Number
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalTasks / this.limit);
        },
        showFirstPage() {
            return this.currentPage > 3;
        },
        showLastPage() {
            return this.currentPage < this.totalPages - 2;
        },
        showFirstEllipsis() {
            return this.currentPage > 4;
        },
        showLastEllipsis() {
            return this.currentPage < this.totalPages - 3;
        },
        showPreviousPage() {
            return this.currentPage > 1;
        },
        showNextPage() {
            return this.currentPage < this.totalPages;
        }
    },
    methods: {
        changePage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.$emit('page-changed', page);
            }
        }
    }
};
</script>
  
  
<style scoped>
.pagination .page-item {
    margin-right: 8px;
    min-width: 36px;
    text-align: center;
    border-radius: 0.70rem;
}

.pagination .page-item:last-child {
    margin-right: 0;
}

.pagination .page-link {
    border-radius: 0.70rem;
}
</style>
