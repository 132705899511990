<template>
    <div class="container">
        <h3 class="my-3">Kalender</h3>
        <div class="row">
            <div class="col">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="showFinishedTasks">
                    Näita lõpetatud töid
                </label>
            </div>
            <div class="col">
                <button class="btn btn-primary btn-sm w-100" @click="showAddEventModal(null)">Lisa kirje</button>
            </div>
        </div>
    </div>
    <FullCalendar ref="fullCalendar" :options='calendarOptions' class="bg-white mt-3" />    
    <AddEventModal :show="isAddEventModalVisible" :event="selectedEvent" @close="isAddEventModalVisible = false" @event-saved="fetchEvents" />
</template>
<script>
import FullCalendar from '@fullcalendar/vue3';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import etLocale from '@fullcalendar/core/locales/et';
import axios from '@/services/axios';
import AddEventModal from '@/components/AddEventModal.vue';

export default {
    components: {
        FullCalendar,
        AddEventModal
    },
    data() {
        return {
            showFinishedTasks: false,
            isAddEventModalVisible: false,
            selectedEvent: null,
            calendarOptions: {
                locale: etLocale,
                plugins: [timeGridPlugin, bootstrap5Plugin],
                initialView: 'timeGridDay',
                height: 'auto',
                nowIndicator: true,
                titleFormat: { year: '2-digit', month: '2-digit', day: '2-digit' },
                views: {
                    timeGridThreeDay: {
                        type: 'timeGrid',
                        duration: { days: 3 },
                        buttonText: '3 päeva'
                    }
                },
                headerToolbar: {
                    left: 'timeGridDay,timeGridThreeDay,timeGridWeek',
                    center: 'prev,title,next',
                    right: ''
                },
                events: (fetchInfo, successCallback, failureCallback) => {
                    axios.get('/events', {
                        params: {
                            start: fetchInfo.startStr,
                            end: fetchInfo.endStr,
                            finished: this.showFinishedTasks ? null : 0
                        }
                    })
                        .then(response => {
                            successCallback(response.data.data);
                        })
                        .catch(error => {
                            failureCallback(error);
                        });
                },
                eventClick: (clickInfo) => {
                    clickInfo.jsEvent.preventDefault();
                    clickInfo.jsEvent.stopPropagation();
                    if (clickInfo.event.extendedProps.resource_id > 0) {
                        const selectedEvent = {
                            resource_id: clickInfo.event.extendedProps.resource_id,
                            profile_id: clickInfo.event.extendedProps.profile_id,
                            start_time: clickInfo.event.startStr,
                            duration: clickInfo.event.extendedProps.amount,
                            description: clickInfo.event.extendedProps.notes
                        };
                        this.showAddEventModal(selectedEvent);
                    } else {
                        const taskId = clickInfo.event.id;
                        this.$router.push(`/tasks/${taskId}`);
                    }
                },
            }
        }
    },
    methods: {
        showAddEventModal(event = null) {
            this.selectedEvent = event;
            this.isAddEventModalVisible = true;
        },
        fetchEvents() {
            this.$refs.fullCalendar.getApi().refetchEvents();
        }
    },
    watch: {
        showFinishedTasks() {
            this.fetchEvents();
        }
    }
}
</script>
<style>
.fc-toolbar {
    display: block !important;
    margin: 0 5px 0 5px;
}

.fc-timegrid-axis-cushion {
    font-size: 10px !important;
}

.fc-col-header-cell-cushion {
    font-size: 14px !important;
    text-decoration: none !important;
}

.fc-timegrid-slot-label-cushion {
    font-size: 14px !important;
}

.fc-event-main {
    font-size: 12px !important;
}

.fc-toolbar-chunk {
    width: 100%;
}

.fc-button-group {
    width: 100%;
}

.fc-timeGridDay-button,
.fc-timeGridThreeDay-button,
.fc-timeGridWeek-button {
    background-color: var(--bs-light) !important;
    font-size: 14px !important;
    color: var(--bs-primary) !important;
    border: 0 !important;
    border-radius: 40px !important;
    outline: none !important;
    box-shadow: none !important;
}

.fc-timeGridDay-button:focus,
.fc-timeGridThreeDay-button:focus,
.fc-timeGridWeek-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.fc-timeGridDay-button.fc-button-active,
.fc-timeGridThreeDay-button.fc-button-active,
.fc-timeGridWeek-button.fc-button-active {
    background-color: var(--bs-red) !important;
    color: var(--bs-white) !important;
}

.fc-prev-button,
.fc-next-button {
    width: 40px;
    height: 40px;
    font-size: 14px !important;
    background-color: var(--bs-white) !important;
    color: var(--bs-primary) !important;
    border: 1px solid var(--bs-red) !important;
    border-radius: 12px !important;
    outline: none !important;
    box-shadow: none !important;
}

.fc-toolbar-chunk .fc-button-group {
    border-radius: 40px;
    padding: 6px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: var(--bs-light) !important;
    border: 1px solid var(--bs-border-color);
}

.fc-toolbar-title {
    display: inline;
}

.fc-header-toolbar:nth-child(1) .fc-toolbar-chunk div {
    display: flex;
    justify-content: space-between;
}

.fc-toolbar-title {
    font-size: 22px !important;
}
</style>