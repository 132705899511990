<template>
    <router-link 
      v-for="task in tasks" 
      :key="task.id" 
      :to="`/tasks/${task.id}`" 
      class="text-decoration-none">
      <TaskComponent :task="task" />
    </router-link>
</template>

<script>
import TaskComponent from './TaskComponent.vue';

export default {
    name: 'TaskList',
    components: {
        TaskComponent
    },
    props: {
        tasks: {
            type: [Array],
            required: true
        }
    },
};
</script>

<style scoped>

</style>