<template>
    <div class="container">
        <span @click="$emit('close')" role="button"><small><i
                    class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h2 class="my-3">{{ this.originalComment ? 'Vasta kommentaarile' : 'Lisa kommentaar' }}</h2>
        <div class="mb-3">
            <label for="commentInput" class="form-label"><small>Sisu</small></label>
            <textarea id="commentInput" class="form-control bg-light" v-model="comment"
                placeholder="Sisesta kommentaari sisu"></textarea>
        </div>
        <div class="mb-3">
            <label class="form-label small">Teavita kommentaarist</label>
            <MultiSelect v-model="selectedUsers" :options="users" optionLabel="name" placeholder="Vali kasutajad" :maxSelectedLabels="3" filter class="w-100 bg-light" :showToggleAll="false" :selectedItemsLabel="'{0} valitud'" />
        </div>
        <div class="row mt-3">
            <div class="col">
                <button @click="$emit('close')" class="btn border-1 border-primary text-primary w-100">Loobu</button>
            </div>
            <div class="col">
                <button @click="submitComment" class="btn btn-primary w-100">Postita</button>
            </div>
        </div>
    </div>
</template>

<script>

import axios from '@/services/axios';
import MultiSelect from 'primevue/multiselect';

export default {
    components: {
        MultiSelect,
    },
    props: {
        taskId: {
            type: Number,
            required: true
        },
        originalComment: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            comment: null,
            notify: false,
            users: [],
            selectedUsers: []
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        async submitComment() {
            try {
                const originalCommentId = this.originalComment ? this.originalComment.id : null;
                const payload = {
                    parent_id: originalCommentId,
                    comment: this.comment,
                    users: this.selectedUsers,
                    notify: true,
                };

                const response = await axios.post(`/tasks/${this.taskId}/comments`, payload);

                if (response.status === 200) {
                    this.$emit('comment-added');
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error saving comment:', error);

            }
        },
        async fetchUsers() {
            let url = `/tasks/${this.taskId}/comments/users`;

            if (this.originalComment && this.originalComment.user_id) {
                url += `?users=${this.originalComment.user_id}`;
            }

            try {
                const response = await axios.get(url);
                this.users = response.data.data;
                this.selectedUsers = this.users.filter(user => user.selected).map(user => user.id);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },
        toggleCheckbox(user) {
            const index = this.selectedUsers.indexOf(user.id);
            if (index > -1) {
                this.selectedUsers.splice(index, 1);
            } else {
                this.selectedUsers.push(user.id);
            }
        }
    }
}
</script>

<style>
.p-multiselect-items {
    padding: 0 !important;
}
.p-multiselect-label {
    font-size: 0.875rem;
    color: var(--bs-body-color);
    font-family: var(--bs-body-font-family);
}
.p-checkbox.p-highlight .p-checkbox-box {
    background: var(--bs-red);
    border: var(--bs-red);
}
</style>
