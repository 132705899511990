<template>
    <div id="act_form" v-if="formStructure">
        <section v-for="section in formStructure.sections" :key="section.id" :data-id="section.id">
            <div style="margin: 10px 0 5px 0;">
                <strong>{{ section.title }}</strong>
            </div>
            <div v-if="section.tables">
                <div v-for="table in section.tables" :key="table.title" :data-id="section.id"
                    :data-addable="table.addable" :data-table="table.table">
                    <table class="table-act">
                        <thead v-if="table.columns">
                            <tr>
                                <th v-for="column in table.columns" :key="column.label">{{ column.label }}</th>
                                <th v-if="table.addable"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in table.rows" :key="i">
                                <input v-if="row.id" type="hidden" name="id" :value="row.id" />
                                <td v-for="(cell, index) in row.cells" :key="index" :colspan="cell.colspan || 1">
                                    <template v-if="(cell.type || (table.columns && table.columns[index].type)) && !(table.columns && table.columns[index].disabled_if_id && row.id)">
                                        <component
                                            :is="getInputComponent(cell.type || (table.columns && table.columns[index].type))"
                                            :name="cell.name || (table.columns && table.columns[index].name)"
                                            :value="cell.value || (cell.type === 'checkbox' || (table.columns && table.columns[index].type === 'checkbox') ? false : '')"
                                            :label="cell.label || ''" :text="cell.text || ''"
                                            :placeholder="cell.placeholder || ''" :row-index="i"
                                            :disabled="table.columns && table.columns[index].disabled_if_id && row.id"
                                            @update:value="updateCellValue(section.id, table.title, i, index, $event)" />
                                    </template>
                                    <template v-else>
                                        {{ cell.text || cell.value || '' }}
                                    </template>
                                </td>
                                <td class="text-nowrap" v-if="table.addable || table.removable">
                                    <button v-if="table.addable" class="btn btn-sm bg-primary-subtle text-primary" type="button"
                                        @click="addRow(table, i)">+</button>
                                    <button v-if="table.removable" class="btn btn-sm bg-danger-subtle text-danger" type="button"
                                        @click="deleteRow(table, i)">-</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="section.divs">
                <div v-for="div in section.divs" :key="div.text">{{ div.text }}</div>
            </div>
        </section>
        
    <div class="bg-primary p-3 mt-3">
        <router-link :to="`/tasks/${$route.params.id}`"
            class="text-decoration-none ms-auto btn border-1 border-white text-white me-3">Tagasi</router-link>
        <button class="btn btn-light me-3" @click="saveFormData">Salvesta</button>
        <button class="btn btn-light me-3" @click="confirmFormData">Kinnita</button>
        <button v-if="formStructure.deletable" class="btn btn-danger me-3" @click="deleteFormData">Kustuta</button>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import InputText from '@/components/inputs/InputText.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputTextarea from '@/components/inputs/InputTextarea.vue';
import InputDate from '@/components/inputs/InputDate.vue';
import InputTime from '@/components/inputs/InputTime.vue';
import InputSignature from '@/components/inputs/InputSignature.vue';

export default {
    data() {
        return {
            formStructure: null,
        };
    },
    components: {
        InputText,
        InputCheckbox,
        InputTextarea,
        InputDate,
        InputTime,
        InputSignature
    },
    methods: {
        async fetchTemplate() {
            try {
                const { id, template_id } = this.$route.params;
                const response = await axios.get(`/tasks/${id}/act/${template_id}`);
                this.formStructure = response.data.data;
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        },
        getInputComponent(type) {
            switch (type) {
                case 'checkbox':
                    return 'InputCheckbox';
                case 'textarea':
                    return 'InputTextarea';
                case 'date':
                    return 'InputDate';
                case 'time':
                    return 'InputTime';
                case 'signature':
                    return 'InputSignature';
                default:
                    return 'InputText';
            }
        },
        updateCellValue(sectionId, tableTitle, rowIndex, cellIndex, newValue) {
            const section = this.formStructure.sections.find(sec => sec.id === sectionId);
            if (section) {
                const table = section.tables.find(tbl => tbl.title === tableTitle);
                if (table) {
                    table.rows[rowIndex].cells[cellIndex].value = newValue;
                }
            }
        },
        addRow(table, rowIndex) {
            const newRow = { cells: table.columns.map(() => ({})) };
            table.rows.splice(rowIndex + 1, 0, newRow);
        },

        deleteRow(table, rowIndex) {
            table.rows.splice(rowIndex, 1);
        },
        collectTableData(div) {
            const tables = div.querySelectorAll('table');
            const rowData = [];
            tables.forEach(table => {
                const rows = table.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
                for (let i = 0; i < rows.length; i++) {
                    const inputs = rows[i].getElementsByTagName('input');
                    const textareas = rows[i].getElementsByTagName('textarea');
                    const rowObject = {};
                    for (let j = 0; j < inputs.length; j++) {
                        const name = inputs[j].name.replace(/\[\d+\]/, '');
                        if (inputs[j].type === 'checkbox') {
                            rowObject[name] = inputs[j].checked ? true : false;
                        } else if (inputs[j].type === 'radio') {
                            if (inputs[j].checked) {
                                rowObject[name] = inputs[j].value;
                            }
                        } else {
                            rowObject[name] = inputs[j].value;
                        }
                    }
                    for (let k = 0; k < textareas.length; k++) {
                        const name = textareas[k].name.replace(/\[\d+\]/, '');
                        rowObject[name] = textareas[k].value;
                    }
                    rowData.push(rowObject);
                }
            });
            return rowData;
        },

        collectFormData() {
            const formData = {};
            this.$el.querySelectorAll('#act_form section').forEach(section => {
                const sectionTitle = section.getAttribute('data-id');
                formData[sectionTitle] = sectionTitle === 'optional_notes' || sectionTitle === 'notes' || sectionTitle === 'battery' || sectionTitle === 'footer' ? {} : [];
                section.querySelectorAll('div[data-id]').forEach(div => {
                    if (div.hasAttribute('data-table')) {
                        formData[sectionTitle] = this.collectTableData(div);
                    } else {
                        const tableData = {};
                        div.querySelectorAll('table').forEach(table => {
                            const rows = table.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
                            for (let i = 0; i < rows.length; i++) {
                                const inputs = rows[i].getElementsByTagName('input');
                                const textareas = rows[i].getElementsByTagName('textarea');
                                for (let j = 0; j < inputs.length; j++) {
                                    const name = inputs[j].name.replace(/\[\d+\]/, '');
                                    if (inputs[j].type === 'checkbox') {
                                        tableData[name] = inputs[j].checked ? true : false;
                                    } else if (inputs[j].type === 'radio') {
                                        if (inputs[j].checked) {
                                            tableData[name] = inputs[j].value;
                                        }
                                    } else {
                                        tableData[name] = inputs[j].value;
                                    }
                                }
                                for (let k = 0; k < textareas.length; k++) {
                                    const name = textareas[k].name.replace(/\[\d+\]/, '');
                                    tableData[name] = textareas[k].value;
                                }
                            }
                        });
                        formData[sectionTitle] = tableData;
                    }
                });
            });
            return formData;
        },

        async saveFormData() {
            const formData = this.collectFormData();
            try {
                const { id, template_id } = this.$route.params;
                await axios.post(`/tasks/${id}/act/${template_id}`, {
                    data: JSON.stringify(formData)
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.fetchTemplate();
            } catch (error) {
                console.error('Error saving form data:', error);
            }
        },
        async deleteFormData() {
            try {
                const { id, template_id } = this.$route.params;
                await axios.delete(`/tasks/${id}/act/${template_id}`);
                this.$router.push(`/tasks/${id}`);
            } catch (error) {
                console.error('Error clearing form data:', error);
            }
        },
        async confirmFormData() {
            const formData = this.collectFormData();
            try {
                const { id, template_id } = this.$route.params;
                await axios.post(`/tasks/${id}/act/${template_id}/confirm`, {
                    data: JSON.stringify(formData)
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.$router.push(`/tasks/${id}`);
            } catch (error) {
                console.error('Error saving form data:', error);
            }
        }

    },
    mounted() {
        this.fetchTemplate();
    },
};
</script>

<style>
.table-act {
    width: 100%;
    border-collapse: collapse;
}

.table-act th,
.table-act td {
    padding: 5px;
    text-align: left;
}

.input-wide {
    width: 100%;
}

#act_form {
    width: 100%;
    margin: auto;
}
</style>