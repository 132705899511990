<template>
    <div class="container">
        <h2>Teavitused</h2>

        <div v-if="unreadNotifications.length > 0">
            <h6 class="my-3">UUED</h6>
            <router-link v-for="notification in unreadNotifications" :key="notification.id"
                :to="getRouteObject(notification)" class="text-decoration-none" @click="markAsRead(notification)">
                <div class="card mb-3 shadow border-danger border-1">
                    <div class="card-body">
                        <span class="badge rounded-pill text-bg-light">{{ notification.type }}</span>
                        <h6 class="small mt-2">{{ notification.title }}</h6>
                        <span class="extra-small text-muted">{{ formatTimestamp(notification.created_on) }}</span>
                    </div>
                </div>
            </router-link>
        </div>

        <div v-if="readNotifications.length > 0">
            <h6 class="my-3">LOETUD</h6>
            <router-link v-for="notification in readNotifications" :key="notification.id"
                :to="getRouteObject(notification)" class="text-decoration-none">
                <div class="card mb-3 shadow border-0">
                    <div class="card-body">
                        <span class="badge rounded-pill text-bg-light">{{ notification.type }}</span>
                        <h6 class="small mt-2">{{ notification.title }}</h6>
                        <span class="extra-small text-muted">{{ formatTimestamp(notification.created_on) }}</span>
                    </div>
                </div>
            </router-link>
        </div>

        <ListPagination :currentPage="currentPage" :totalItems="totalNotifications" :limit="limit"
            @page-changed="fetchNotifications" />
    </div>
</template>

<script>
import axios from '@/services/axios';
import ListPagination from '@/components/ListPagination.vue';
import { useFormatTimestamp } from '@/composables/useFormatTimestamp';

export default {
    data() {
        return {
            unreadNotifications: [],
            readNotifications: [],
            currentPage: 1,
            totalNotifications: 0,
            limit: 20,
        };
    },
    components: {
        ListPagination
    },
    setup() {
        const { formatTimestamp } = useFormatTimestamp();
        return { formatTimestamp };
    },
    methods: {
        getRouteObject(notification) {
            let route = { path: notification.link };

            if (notification.comment_id) {
                route.hash = `#comment-${notification.comment_id}`;
            }

            return route;
        },
        async fetchNotifications(page = 1) {
            const userId = JSON.parse(localStorage.getItem('user')).id;
            try {
                const response = await axios.get(`/notifications?user_id=${userId}&page=${page}&limit=${this.limit}`);
                const notifications = response.data.data;
                this.totalNotifications = response.data.total;
                this.currentPage = page;

                this.unreadNotifications = notifications.filter(n => !n.is_read);
                this.readNotifications = notifications.filter(n => n.is_read);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        async markAsRead(notification) {
            try {
                await axios.post(`/notifications/${notification.id}/read`);

                this.$router.push(this.getRouteObject(notification));
            } catch (error) {
                console.error('Error marking notification as read:', error);
            }
        }
    },
    mounted() {
        this.fetchNotifications();
    }
};
</script>
