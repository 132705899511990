import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './services/axios';
import { initializeMsal } from '@/composables/msalConfig';
import GlobalAlert from './components/GlobalAlert.vue';
import PrimeVue from 'primevue/config';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@vuepic/vue-datepicker/dist/main.css'
import 'primevue/resources/themes/aura-light-green/theme.css'
import './assets/custom.css';

import './registerServiceWorker'

initializeMsal().then(() => {
    createApp(App)
    .use(router)
    .use(PrimeVue, { 
        // unstyled: true,
        zIndex: {
            modal: 1100,        //dialog, sidebar
            overlay: 1100,      //dropdown, overlaypanel
            menu: 1100,         //overlay menus
            tooltip: 1100       //tooltip
        }
    })
    .component('global-alert', GlobalAlert)
    .mount('#app');
}).catch(error => {
    console.error('Error initializing MSAL', error);
});