<template>
    <input
      :type="type"
      :name="name"
      :value="localValue"
      @input="onInput"
      :placeholder="placeholder"
      class="input-wide"
    />
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String,
        required: true
      },
      value: {
        type: [String, Number],
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        localValue: this.value
      };
    },
    watch: {
      value(newVal) {
        this.localValue = newVal;
      }
    },
    methods: {
      onInput(event) {
        this.localValue = event.target.value;
        this.$emit('update:value', event.target.value);
      }
    }
  };
  </script>
  
  <style scoped>
  .input-wide {
    width: 100%;
  }
  </style>
  